export class WorkshopBudgetState {
  static readonly PENDING_ID = 1;
  static readonly APPOINTMENT_ID = 2;
  static readonly ADMITTED_ID = 3;
  static readonly DIAGNOSIS_ID = 4;
  static readonly PURCHASE_REQUESTED_ID = 5;
  static readonly REPAIR_ID = 6;
  static readonly FINISHED_ID = 7;
  static readonly DELIVERED_ID = 8;
  static readonly REJECTED_ID = 9;
  static readonly PENDING_LABEL = "Pendiente";
  static readonly APPOINTMENT_LABEL = "Turno";
  static readonly ADMITTED_LABEL = "Ingreso";
  static readonly DIAGNOSIS_LABEL = "Diagnóstico";
  static readonly PURCHASE_REQUESTED_LABEL = "Solicitud de Compra";
  static readonly REPAIR_LABEL = "Reparación";
  static readonly FINISHED_LABEL = "Finalizado";
  static readonly DELIVERED_LABEL = "Entregado";
  static readonly REJECTED_LABEL = "Rechazado";

  readonly id!: number;
  readonly name!: string;

  constructor(data: Partial<WorkshopBudgetState>) {
    Object.assign(this, data);
  }

  static readonly labels: { [key: number]: string } = {
    [WorkshopBudgetState.PENDING_ID]: WorkshopBudgetState.PENDING_LABEL,
    [WorkshopBudgetState.APPOINTMENT_ID]: WorkshopBudgetState.APPOINTMENT_LABEL,
    [WorkshopBudgetState.ADMITTED_ID]: WorkshopBudgetState.ADMITTED_LABEL,
    [WorkshopBudgetState.DIAGNOSIS_ID]: WorkshopBudgetState.DIAGNOSIS_LABEL,
    [WorkshopBudgetState.PURCHASE_REQUESTED_ID]:
      WorkshopBudgetState.PURCHASE_REQUESTED_LABEL,
    [WorkshopBudgetState.REPAIR_ID]: WorkshopBudgetState.REPAIR_LABEL,
    [WorkshopBudgetState.FINISHED_ID]: WorkshopBudgetState.FINISHED_LABEL,
    [WorkshopBudgetState.DELIVERED_ID]: WorkshopBudgetState.DELIVERED_LABEL,
  };

  static readonly columnOrder = [
    WorkshopBudgetState.PENDING_ID,
    WorkshopBudgetState.APPOINTMENT_ID,
    WorkshopBudgetState.ADMITTED_ID,
    WorkshopBudgetState.DIAGNOSIS_ID,
    WorkshopBudgetState.PURCHASE_REQUESTED_ID,
    WorkshopBudgetState.REPAIR_ID,
    WorkshopBudgetState.FINISHED_ID,
    WorkshopBudgetState.DELIVERED_ID,
  ];

  static readonly getColumnBackgroundColor = (columnId: number) => {
    switch (columnId) {
      case WorkshopBudgetState.PENDING_ID:
      case WorkshopBudgetState.APPOINTMENT_ID:
      case WorkshopBudgetState.ADMITTED_ID:
      case WorkshopBudgetState.DIAGNOSIS_ID:
      case WorkshopBudgetState.FINISHED_ID:
      case WorkshopBudgetState.REPAIR_ID:
      case WorkshopBudgetState.PURCHASE_REQUESTED_ID:
      case WorkshopBudgetState.DELIVERED_ID:
        return "rgba(170, 170, 170, 0.1)";
      default:
        return "white";
    }
  };
}

import Tooltip from "@mui/material/Tooltip";
import LinkIcon from "@mui/icons-material/Link";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useState } from "react";
import { Link } from "react-router-dom";

interface LinkTooltipProps {
  linkCopy: string;
  text: number;
  color: string;
  link: string;
}

const LinkTooltip: React.FC<LinkTooltipProps> = ({
  link,
  text,
  color,
  linkCopy,
}) => {
  const [isCopiedLink, setIsCopiedLink] = useState(false);

  const handleCopyLink = (link: string) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          setIsCopiedLink(true);
          setTimeout(() => {
            setIsCopiedLink(false);
          }, 5000);
        })
        .catch((error) => {
          console.error("Error al copiar al portapapeles:", error);
        });
    } else {
      console.error(
        "La API del portapapeles no está disponible en este navegador."
      );
    }
  };

  return (
    <Tooltip
      arrow
      slotProps={{
        arrow: { style: { backgroundColor: "white" } },
        tooltip: {
          style: {
            backgroundColor: "white",
            border: "1px solid black",
          },
        },
      }}
      title={
        <div
          className="center-text"
          onClick={() => handleCopyLink(linkCopy)}
          style={{ cursor: "pointer", color: "black" }}
        >
          {isCopiedLink ? (
            <>
              <strong>Copiado</strong> <br />
              <CheckCircleIcon color="success" />
            </>
          ) : (
            <>
              <strong>Copiar enlace</strong>
              <br />
              <LinkIcon />
            </>
          )}
        </div>
      }
      placement="right"
    >
      {link ? (
        <span>
          <Link
            style={{ textDecoration: "none", color: color, cursor: "pointer" }}
            target="_blank"
            to={link}
          >
            {text}
          </Link>
        </span>
      ) : (
        <a style={{ color: color, cursor: "pointer" }}>{text}</a>
      )}
    </Tooltip>
  );
};

export default LinkTooltip;

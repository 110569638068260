import { Budget } from "../../../../entities/budget.entity";
import { BudgetService } from "../../../../services/BudgetService";
import { InfiniteQueryOptions } from "../../../../types/InfiniteQueryOptions";
import { PageResponse } from "../../../../types/PagedData";
import { useInfiniteKanbanQuery } from "../useInfiniteKanbanQuery";

const budgetService = new BudgetService();

export async function fetchBudgetsByState({
  pageParam = 1,
  queryKey,
}: {
  pageParam?: number;
  queryKey: [string, number, string];
}): Promise<PageResponse<Budget>> {
  const [_key, stateId, searchTerm] = queryKey;

  const { data, totalCount, pageSize } =
    await budgetService.findByStatesPaginated(
      [stateId],
      searchTerm,
      pageParam,
      20
    );

  return {
    data,
    totalCount,
    pageSize,
    currentPage: pageParam,
  };
}

export function useInfiniteBudgetsByState(options: InfiniteQueryOptions) {
  return useInfiniteKanbanQuery<Budget>(
    fetchBudgetsByState,
    ["budgetsByState", options.stateId, options.searchTerm]
  );
}
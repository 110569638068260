import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { GridFilterInputValueProps } from "@mui/x-data-grid";

interface FilterOption {
  id: number;
  label: string;
}

interface GenericSelectFilterProps extends GridFilterInputValueProps {
  label: string;
  options: FilterOption[];
}

export default function GenericSelectFilter(props: GenericSelectFilterProps) {
  const { item, applyValue, label, options } = props;

  const handleFilterChange = (event: SelectChangeEvent) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <>
      <FormControl fullWidth variant="standard" sx={{ minWidth: "200px" }}>
        <InputLabel id={`${label}-filter-label`} shrink={true}>
          Filtrar {label}
        </InputLabel>
        <Select
          value={
            item.value !== undefined && item.value !== null
              ? String(item.value)
              : ""
          }
          onChange={handleFilterChange}
          labelId={`${label}-filter-label`}
          label={label}
          displayEmpty
        >
          <MenuItem value="">
            <em>Todos</em>
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

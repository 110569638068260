import { ReactNode } from "react";
import { Box, Skeleton } from "@mui/material";
import {
  Droppable,
  DroppableProvided,
} from "@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration";
import { useDarkMode } from "../../ThemeContext";
import Item from "./Item";

interface BasicColumnProps<T> {
  stateId: number;
  items: T[];
  columnName: string;
  bgColor: string;
  handleOpen: (item: T) => void;
  renderItem: (task: T) => ReactNode;
  totalCount?: number;
  isLoading?: boolean;
  children?: ReactNode;
}

function BasicColumn<T extends { id: number | string }>({
  stateId,
  items,
  columnName,
  bgColor,
  handleOpen,
  renderItem,
  totalCount,
  isLoading,
  children,
}: BasicColumnProps<T>) {
  const { isDarkMode } = useDarkMode();

  return (
    <Box
      sx={{
        overflowX: "hidden",
        height: "calc(100vh - 240px)",
        minWidth: "380px",
        display: "flex",
        flexDirection: "column",
        padding: "8px",
        borderRadius: "10px",
        margin: "0px 16px 10px 0px",
        backgroundColor: bgColor,
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px",
          width: "100%",
          backgroundColor: isDarkMode ? "#2E363E" : "white",
          borderRadius: "8px",
          boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
          fontWeight: "medium",
          marginBottom: "8px",
          position: "sticky",
          top: "0",
          zIndex: "100",
        }}
      >
        <Box>{columnName}</Box>
        <Box sx={{ marginLeft: "5px", fontSize: ".8rem" }}>
          {totalCount ? totalCount : ""}
        </Box>
      </Box>

      <Droppable droppableId={stateId.toString()} key={stateId.toString()}>
        {(provided: DroppableProvided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
            }}
          >
            {items.map((task: T, index: number) => (
              <Item
                key={task.id.toString()}
                task={task}
                index={index}
                handleOpen={handleOpen}
                renderItem={renderItem}
              />
            ))}
            {provided.placeholder}
            {children}
          </Box>
        )}
      </Droppable>

      {isLoading && (
        <Skeleton
          sx={{ height: "150px", borderRadius: "10px", flexShrink: 0 }}
          animation="wave"
          variant="rectangular"
        />
      )}
    </Box>
  );
}

export default BasicColumn;

import { WorkshopPurchase } from "../../../../entities/workshop-purchase.entity";
import InfiniteColumn from "../InfiniteColumn";
import { TypeKanbanPurchase } from "./TypeKanbanPurchase";
import { useInfinitePurchasesByState } from "./useInfinitePurchasesByState";
import { useContext } from "react";
import { PurchaseContext } from "../../Purchase/context/PurchaseContext";
import PurchaseKanbanItem from "./PurchaseKanbanItem";
import { parseUTCDate } from "../../../../utils/dateUtils";
import { User } from "../../../../entities/user.entity";
import { getTimeDifferenceInfo } from "../../../../utils/dateUtils";
import { WorkshopPurchaseState } from "../../../../entities/workshop-purchase-state.entity";

const generatePurchaseItems = (
  purchases: WorkshopPurchase[],
  responsibleList: User[],
  stateId: number
): TypeKanbanPurchase[] => {
  const stateColor = WorkshopPurchaseState.colors[stateId];

  return purchases
    .filter(
      (purchase) => purchase.budget !== null && purchase.budget !== undefined
    )
    .map((purchase) => {
      const selectedResponsible = responsibleList?.find(
        (r) => r.id === purchase.responsible_id
      );

      const lastModificationDate = purchase.last_state_change_date;
      const { formattedHoverDate, formattedDate, background, color } =
        getTimeDifferenceInfo(lastModificationDate);

      const budgetArrivalDate = parseUTCDate(
        purchase.budget.workshop_arrival_date
      );
      const budgetExitDate = parseUTCDate(purchase.budget.workshop_exit_date);

      const tags = [
        { title: `SC ${purchase.id}`, ...stateColor },
        { title: `PR ${purchase.budget.id}`, ...stateColor },
        purchase.budget.previous_budget_id
          ? { title: "R", ...stateColor }
          : null,
        purchase.is_additional ? { title: "A", ...stateColor } : null,
      ].filter(
        (data): data is { title: string; bg: string; text: string } =>
          data !== null
      );

      return {
        id: purchase.id.toString(),
        carId: purchase.budget.car.identification,
        brandCar: purchase.budget.car.brand.name.toString(),
        modelCar: purchase.budget.car.carModel?.name.toString(),
        company: purchase.budget.company.name,
        description: purchase.budget.description,
        priorityName: purchase.workshopPurchasePriority?.name,
        priorityId: purchase.workshopPurchasePriority?.id,
        tags,
        name: selectedResponsible?.name,
        lastName: selectedResponsible?.lastname,
        end_date: purchase.end_date,
        workshopName: purchase.budget.workshop.name,
        lastModificationHoverDate: formattedHoverDate,
        lastModificationFormattedDate: formattedDate,
        lastModificationBg: background,
        lastModificationColor: color,
        workshop_arrival_date: budgetArrivalDate,
        workshop_exit_date: budgetExitDate,
        originalPurchase: purchase,
        budget: purchase.budget,
        workshopPurchaseState: purchase.workshopPurchaseState,
        workshop: purchase.workshop,
        workshopPurchaseItems: purchase.workshopPurchaseItems,
      };
    });
};

export default function PurchaseColumn({
  stateId,
  responsibleList,
  searchTerm,
}: {
  stateId: number;
  responsibleList: User[];
  searchTerm: string;
}) {
  const { setOpen, setSelectedPurchase } = useContext(PurchaseContext);

  const renderItem = (task: TypeKanbanPurchase) => {
    return <PurchaseKanbanItem task={task} />;
  };

  return (
    <InfiniteColumn<TypeKanbanPurchase, WorkshopPurchase>
      key={stateId}
      stateId={stateId}
      responsibleList={responsibleList}
      getColumnBackgroundColor={WorkshopPurchaseState.getColumnBackgroundColor}
      searchTerm={searchTerm}
      handleOpen={(item: TypeKanbanPurchase) => {
        setSelectedPurchase(item.originalPurchase);
        setOpen(true);
      }}
      renderItem={renderItem}
      useInfiniteDataQuery={useInfinitePurchasesByState}
      generateColumnItems={generatePurchaseItems}
      stateLabels={WorkshopPurchaseState.labels}
      stateFilterFn={(stateId) => (item: WorkshopPurchase) =>
        item.workshopPurchaseState.id === stateId}
    />
  );
}

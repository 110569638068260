interface Color {
  bg: string;
  text: string;
}

export class WorkshopPurchaseState {
  static readonly QUOTE_ID = 1;
  static readonly QUOTED_ID = 2;
  static readonly BUY_ID = 3;
  static readonly SPEC_ID = 4;
  static readonly FINISHED_ID = 5;
  static readonly DELIVERED_ID = 6;
  static readonly CANCELLED_ID = 7;
  static readonly EXPIRED_ID = 8;
  static readonly REJECTED_ID = 9;
  static readonly QUOTE_LABEL = "Cotizar";
  static readonly QUOTED_LABEL = "Cotizado";
  static readonly BUY_LABEL = "Comprar";
  static readonly DELIVERED_LABEL = "Entregado";
  static readonly FINISHED_LABEL = "Finalizado";
  static readonly SPEC_LABEL = "SPEC";
  static readonly CANCELLED_LABEL = "Cancelado";
  static readonly EXPIRED_LABEL = "Vencida";
  static readonly REJECTED_LABEL = "Rechazada";

  readonly id!: number;
  readonly name!: string;

  constructor(data: Partial<WorkshopPurchaseState>) {
    Object.assign(this, data);
  }

  static readonly labels: Record<number, string> = {
    [WorkshopPurchaseState.QUOTE_ID]: WorkshopPurchaseState.QUOTE_LABEL,
    [WorkshopPurchaseState.QUOTED_ID]: WorkshopPurchaseState.QUOTED_LABEL,
    [WorkshopPurchaseState.BUY_ID]: WorkshopPurchaseState.BUY_LABEL,
    [WorkshopPurchaseState.DELIVERED_ID]: WorkshopPurchaseState.DELIVERED_LABEL,
    [WorkshopPurchaseState.FINISHED_ID]: WorkshopPurchaseState.FINISHED_LABEL,
    [WorkshopPurchaseState.SPEC_ID]: WorkshopPurchaseState.SPEC_LABEL,
    [WorkshopPurchaseState.CANCELLED_ID]: WorkshopPurchaseState.CANCELLED_LABEL,
    [WorkshopPurchaseState.EXPIRED_ID]: WorkshopPurchaseState.EXPIRED_LABEL,
    [WorkshopPurchaseState.REJECTED_ID]: WorkshopPurchaseState.REJECTED_LABEL,
  };

  static readonly columnOrder = [
    WorkshopPurchaseState.QUOTE_ID,
    WorkshopPurchaseState.QUOTED_ID,
    WorkshopPurchaseState.BUY_ID,
    WorkshopPurchaseState.DELIVERED_ID,
    WorkshopPurchaseState.FINISHED_ID,
    WorkshopPurchaseState.EXPIRED_ID,
    WorkshopPurchaseState.SPEC_ID,
    WorkshopPurchaseState.REJECTED_ID,
    WorkshopPurchaseState.CANCELLED_ID,
  ];

  static readonly getColumnBackgroundColor = (columnId: number) => {
    switch (columnId) {
      case WorkshopPurchaseState.QUOTE_ID:
      case WorkshopPurchaseState.BUY_ID:
      case WorkshopPurchaseState.QUOTED_ID:
      case WorkshopPurchaseState.FINISHED_ID:
      case WorkshopPurchaseState.DELIVERED_ID:
      case WorkshopPurchaseState.SPEC_ID:
      case WorkshopPurchaseState.CANCELLED_ID:
      case WorkshopPurchaseState.EXPIRED_ID:
      case WorkshopPurchaseState.REJECTED_ID:
        return "rgba(170, 170, 170, 0.1)";
      default:
        return "white";
    }
  };

  static readonly colors: { [key: number]: Color } = {
    [WorkshopPurchaseState.QUOTE_ID]: { bg: "#fef3c7", text: "#d97706" },
    [WorkshopPurchaseState.QUOTED_ID]: { bg: "#dbeafe", text: "#2563eb" },
    [WorkshopPurchaseState.BUY_ID]: { bg: "#fef3c7", text: "#d97706" },
    [WorkshopPurchaseState.SPEC_ID]: { bg: "#cffafe", text: "#0891b2" },
    [WorkshopPurchaseState.CANCELLED_ID]: { bg: "#d1d5db", text: "#6b7280" },
    [WorkshopPurchaseState.DELIVERED_ID]: { bg: "#d1fae5", text: "#059669" },
    [WorkshopPurchaseState.FINISHED_ID]: { bg: "#d1fae5", text: "#059669" },
    [WorkshopPurchaseState.EXPIRED_ID]: { bg: "#d1d5db", text: "#6b7280" },
    [WorkshopPurchaseState.REJECTED_ID]: { bg: "#d1d5db", text: "#6b7280" },
  };
}

export const stateOptions = [
  {
    id: WorkshopPurchaseState.QUOTE_ID,
    label: WorkshopPurchaseState.QUOTE_LABEL,
  },
  {
    id: WorkshopPurchaseState.QUOTED_ID,
    label: WorkshopPurchaseState.QUOTED_LABEL,
  },
  { id: WorkshopPurchaseState.BUY_ID, label: WorkshopPurchaseState.BUY_LABEL },
  {
    id: WorkshopPurchaseState.SPEC_ID,
    label: WorkshopPurchaseState.SPEC_LABEL,
  },
  {
    id: WorkshopPurchaseState.FINISHED_ID,
    label: WorkshopPurchaseState.FINISHED_LABEL,
  },
  {
    id: WorkshopPurchaseState.DELIVERED_ID,
    label: WorkshopPurchaseState.DELIVERED_LABEL,
  },
  {
    id: WorkshopPurchaseState.CANCELLED_ID,
    label: WorkshopPurchaseState.CANCELLED_LABEL,
  },
  {
    id: WorkshopPurchaseState.EXPIRED_ID,
    label: WorkshopPurchaseState.EXPIRED_LABEL,
  },
  {
    id: WorkshopPurchaseState.REJECTED_ID,
    label: WorkshopPurchaseState.REJECTED_LABEL,
  },
];

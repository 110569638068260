import { useQuery } from "@tanstack/react-query";
import { WorkshopService } from "../services/WorkshopService";

/**
 * Hook para obtener los usuarios de un taller por su ID.
 * @param workshopId ID del taller
 * @returns Objeto con los datos de usuarios, estado de carga y posibles errores
 */
const useWorkshopUsers = (workshopId: number) => {
  const workshopService = new WorkshopService();

  return useQuery({
    queryKey: ["users", workshopId],
    queryFn: () => workshopService.users(workshopId),
    enabled: Boolean(workshopId),
  });
};

export default useWorkshopUsers;

import { WorkshopPurchase } from "../../../../entities/workshop-purchase.entity";
import { WorkshopPurchaseService } from "../../../../services/WorkshopPurchaseService";
import { InfiniteQueryOptions } from "../../../../types/InfiniteQueryOptions";
import { useInfiniteKanbanQuery } from "../useInfiniteKanbanQuery";
import { PageResponse } from "../../../../types/PagedData";

const purchaseService = new WorkshopPurchaseService();

async function fetchPurchasesByState({
  pageParam = 1,
  queryKey,
}: {
  pageParam?: number;
    queryKey: [string, number, string];
  }): Promise<PageResponse<WorkshopPurchase>> {
  const [_, stateId, searchTerm] = queryKey;
  const stateIds = [stateId];
  const { data, totalCount, pageSize } =
    await purchaseService.findByStatesPaginated(
      stateIds,
      searchTerm,
      pageParam,
      20
    );
  return {
    data,
    totalCount,
    pageSize,
    currentPage: pageParam,
  };
}

export function useInfinitePurchasesByState(options: InfiniteQueryOptions) {
  return useInfiniteKanbanQuery<WorkshopPurchase>(
    fetchPurchasesByState,
    ["purchasesByState", options.stateId, options.searchTerm]
  );
}
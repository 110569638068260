import React, { createContext } from "react";
import { Budget } from "../../../../entities/budget.entity";
import { TypeKanbanBudgetGroup } from "../../KanbanBoard/BudgetKanban/TypeKanbanBudget";

export type BudgetProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pagedBudgets: Budget[];
  selectedBudgetData: TypeKanbanBudgetGroup | undefined;
  setSelectedBudgetData: React.Dispatch<
    React.SetStateAction<TypeKanbanBudgetGroup | undefined>
  >;
  loading: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  updateBudget: (
    budgetId: number,
    updatedBudget: Partial<Budget>
  ) => Promise<void>;
  updateMechanics: (budgetId: number, mechanicIds: number[]) => Promise<void>;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  totalCount: number;
  pageSize: number;
};

export const BudgetContext = createContext<BudgetProps>({
  open: false,
  setOpen: () => {},
  pagedBudgets: [],
  selectedBudgetData: undefined,
  setSelectedBudgetData: () => {},
  loading: true,
  fetchNextPage: () => {},
  hasNextPage: undefined,
  isFetchingNextPage: false,
  updateBudget: async () => {},
  updateMechanics: async () => {},
  setFilters: () => {},
  totalCount: 0,
  pageSize: 20,
});

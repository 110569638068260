import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { useContext, useEffect, useMemo, useState } from "react";

import { WorkshopPurchaseItemService } from "../../../services/WorkshopPurchaseItemService";
import { WorkshopPurchaseItemState } from "../../../entities/workshop-purchase-item-state.entity";
import { FormattedNumber } from "react-intl";
import AppToolbarExport from "../../Common/DataGrid/AppToolbarExport";
import { formatUTCDate } from "../../../utils/dateUtils";
import "../../../index.css";
import { PurchaseContext } from "./context/PurchaseContext";
import { User } from "../../../entities/user.entity";
import { WorkshopService } from "../../../services/WorkshopService";
import {
  keepPreviousData,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { WorkshopPurchaseService } from "../../../services/WorkshopPurchaseService";
import AutocompleteFilter from "./AutocompleteFilter";
import { Car } from "../../../entities/car.entity";
import {
  ExtendedGridFilterItem,
  VehicleFilterInput,
} from "./VehicleFilterInput";
import { WorkshopPurchase } from "../../../entities/workshop-purchase.entity";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <AppToolbarExport />
    </GridToolbarContainer>
  );
}

export default function Report() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { purchases, loading } = useContext(PurchaseContext);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(() => {
      const visibilityModel: GridColumnVisibilityModel = {};
      WorkshopPurchaseItemState.getIdAndLabels().forEach((i: any) => {
        visibilityModel[`state_${i.id}`] = false;
      });
      return visibilityModel;
    });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [filters, setFilters] = useState<any>({});

  const service = new WorkshopPurchaseItemService();
  const workshopPurchaseService = new WorkshopPurchaseService();
  const workshopService = new WorkshopService();
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ["reportData", filters, page, pageSize],
    queryFn: async () => {
      const response = await service.getHistoricalReportData(
        page + 1,
        pageSize,
        filters
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });

  const { data: partBrands = [], isLoading: loadingPartBrands } = useQuery({
    queryKey: ["partBrands"],
    queryFn: async () => {
      const response = await workshopPurchaseService.getPartBrands();

      return response;
    },
  });

  const { data: repairSubItems = [], isLoading: loadingSubItems } = useQuery({
    queryKey: ["repairSubItems"],
    queryFn: async () => {
      const response = await workshopPurchaseService.getRepairSubItems();
      return response;
    },
  });

  useEffect(() => {
    void queryClient.prefetchQuery({
      queryKey: ["reportData", filters, page + 1, pageSize],
      queryFn: () =>
        service.getHistoricalReportData(page + 2, pageSize, filters),
    });
  }, [page, pageSize, queryClient, filters]);

  const { data: responsibleList, isLoading: isResponsibleListLoading } =
    useQuery({
      queryKey: ["responsibleList", purchases[0]?.workshop.id],
      queryFn: () => workshopService.users(purchases[0].workshop.id),
    });

  const handlePaginationModelChange = (paginationModel: {
    page: number;
    pageSize: number;
  }) => {
    setPage(paginationModel.page);
    setPageSize(paginationModel.pageSize);
  };

  const handleFilterChange = (model: GridFilterModel) => {
    setFilterModel(model);

    const newFilters = model.items.reduce(
      (acc: { [key: string]: unknown }, filter) => {
        const extendedFilter = filter as ExtendedGridFilterItem;
        const field = mapFilterFields(filter.field, extendedFilter.filterType);
        const value = filter.value;
        if (value) {
          acc[field] = value;
        }
        return acc;
      },
      {}
    );

    setFilters(newFilters);
    setPage(0);
  };

  const mapFilterFields = (field: string, filterType?: string): string => {
    if (field === "vehicle" && filterType) {
      return filterType;
    }

    const fieldMapping: { [key: string]: string } = {
      workshop_purchase_id: "workshop_purchase_id",
      budgetItem: "budget_id",
      id: "id",
      repairSubitem: "repair_subitem_id",
      brand: "part_brand_id",
      description: "description",
      responsible: "responsible_id",
      budget_expiration_days: "expiration_days",
      quantity: "quantity",
      price: "price",
    };
    return fieldMapping[field] || field;
  };

  const useVehiclesData = (budgetIds: number[]) => {
    const vehicleQueries = useQueries({
      queries: budgetIds.map((budgetId) => ({
        queryKey: ["vehicle", budgetId],
        queryFn: async (): Promise<WorkshopPurchase | undefined> => {
          return workshopPurchaseService.findByBudgetId(budgetId);
        },
        staleTime: 1000 * 60 * 5,
        enabled: !!budgetId,
        select: (data: WorkshopPurchase | undefined) => ({
          budgetId,
          car: data?.budget?.car,
        }),
      })),
    });

    const vehicles: Record<number, Car> = {};
    const isLoadingVehiculo = vehicleQueries.some((query) => query.isLoading);
    const isError = vehicleQueries.some((query) => query.isError);

    vehicleQueries.forEach((query) => {
      if (query.data?.car) {
        vehicles[query.data.budgetId] = query.data.car;
      }
    });

    return {
      vehicles,
      isLoadingVehiculo,
      isError,
    };
  };

  const budgetIds = useMemo(() => {
    const ids = (data?.data || [])
      .map((row) => row.budgetItem?.budget_id)
      .filter(Boolean);
    return [...new Set(ids)];
  }, [data?.data]);

  const { vehicles, isLoadingVehiculo, isError } = useVehiclesData(budgetIds);

  const { data: brands = [], isLoading: loadingBrands } = useQuery({
    queryKey: ["allBrands"],
    queryFn: workshopPurchaseService.getAllBrands,
    staleTime: 1000 * 60 * 5,
  });

  const { data: carModels = [], isLoading: loadingCarModels } = useQuery({
    queryKey: ["allCarModels"],
    queryFn: workshopPurchaseService.getAllCarModels,
    staleTime: 1000 * 60 * 5,
  });

  const { data: carVersions = [], isLoading: loadingCarVersions } = useQuery({
    queryKey: ["allCarVersions"],
    queryFn: workshopPurchaseService.getAllCarVersions,
    staleTime: 1000 * 60 * 5,
  });

  const initialColumns: GridColDef[] = [
    {
      field: "workshop_purchase_id",
      headerName: "Compra",
      flex: 0.5,
      filterable: true,
    },
    {
      field: "budgetItem",
      headerName: "Presupuesto",
      flex: 0.5,
      filterable: true,
      valueGetter: (params) => {
        return params.row.budgetItem ? params.row.budgetItem.budget_id : "N/A";
      },
    },
    {
      field: "vehicle",
      headerName: "Vehículo",
      flex: 2,
      filterable: true,
      valueGetter: (params) => {
        const budgetId = params.row.budgetItem?.budget_id;

        if (isError) return "Error al cargar";
        if (isLoadingVehiculo) return "Cargando...";

        const car = vehicles[budgetId];
        return car ? Car.getSummary(car) : "N/A";
      },
      filterOperators: [
        {
          label: "Contains",
          value: "contains",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) return null;

            return ({ value }) => {
              return value
                ?.toLowerCase()
                .includes(filterItem.value.toLowerCase());
            };
          },
          InputComponent: (props) => (
            <VehicleFilterInput
              {...props}
              brands={brands}
              carModels={carModels}
              carVersions={carVersions}
              loading={loadingBrands || loadingCarModels || loadingCarVersions}
            />
          ),
        },
      ],
    },

    {
      field: "id",
      headerName: "Item ID",
      flex: 0.5,
      filterable: true,
    },
    {
      field: "repairSubitem",
      headerName: "Items de reparación",
      flex: 1.5,
      filterable: true,
      filterOperators: [
        {
          label: "Es",
          value: "equals",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return ({ value }) => {
              return value === filterItem.value;
            };
          },
          InputComponent: (props) => (
            <AutocompleteFilter
              {...props}
              label="Items de repación"
              loading={loadingSubItems}
              options={repairSubItems}
              getOptionLabel={(option) => (option ? option.name : "")}
              getOptionValue={(option) => (option ? option.id : "")}
            />
          ),
        },
      ],
      valueGetter: (params) => {
        return params.row.budgetItem
          ? params.row.budgetItem.repairSubitem.name
          : "N/A";
      },
    },
    {
      field: "price",
      headerName: "Precio",
      flex: 1,
      renderCell: (params) => (
        <FormattedNumber
          value={params.row.price}
          style="currency"
          currency="ARS"
        />
      ),
    },
    {
      field: "quantity",
      headerName: "Cantidad",
      flex: 1,
      renderCell: (params) => <FormattedNumber value={params.row.quantity} />,
    },
    {
      field: "brand",
      headerName: "Marca de items",
      flex: 1,
      filterable: true,
      filterOperators: [
        {
          label: "Es",
          value: "equals",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return ({ value }) => {
              return value === filterItem.value;
            };
          },
          InputComponent: (props) => (
            <AutocompleteFilter
              {...props}
              label="Filtrar Marca"
              loading={loadingPartBrands}
              options={partBrands}
              getOptionLabel={(option) => (option ? option.name : "")}
              getOptionValue={(option) => (option ? option.id : "")}
            />
          ),
        },
      ],
    },
    {
      field: "description",
      headerName: "Descripción",
      flex: 1,
      filterable: true,
    },
    {
      field: "responsible",
      headerName: "Responsable",
      flex: 1,
      filterable: true,
      filterOperators: [
        {
          label: "Es",
          value: "equals",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return ({ value }) => {
              return value === filterItem.value;
            };
          },
          InputComponent: (props) => (
            <AutocompleteFilter
              {...props}
              label="Filtrar Responsable"
              options={responsibleList || []}
              getOptionLabel={(option) =>
                option ? `${option.name} ${option.lastname}` : ""
              }
              getOptionValue={(option) => (option ? option.id : "")}
            />
          ),
        },
      ],
      valueGetter: (params) => {
        if (!responsibleList) return "";

        const purchase = purchases.find(
          (p) => p.id === params.row.workshop_purchase_id
        );

        if (purchase) {
          if (Array.isArray(responsibleList)) {
            const responsible = responsibleList.find(
              (r: User) => r.id === purchase.responsible_id
            );

            return responsible
              ? `${responsible.name} ${responsible.lastname}`
              : "";
          }
        }
        return "";
      },
    },
    {
      field: "budget_expiration_days",
      headerName: "Días de expiración",
      flex: 1,
      filterable: true,
      valueGetter: (params) => {
        const purchase = purchases.find(
          (p) => p.id === params.row.workshop_purchase_id
        );

        if (purchase) {
          return purchase.budget.expiration_days || "";
        }

        return "";
      },
    },
  ];

  const getColumns = () => {
    return initialColumns.concat(
      WorkshopPurchaseItemState.getIdAndLabels().map((i: any) => ({
        field: `state_${i.id}`,
        headerName: i.label,
        flex: 1,
        cellClassName: "hoverable-column",
        valueGetter: (params) => {
          const stateValue = params.row[`state_${i.id}`];
          return stateValue ? formatUTCDate(stateValue) : "";
        },
      }))
    );
  };

  return (
    <>
      <h2>Reporte Item de Compras</h2>
      <DataGrid
        sx={{ minHeight: "300px" }}
        loading={isLoading || isResponsibleListLoading || loading}
        rows={data?.data || []}
        columns={getColumns()}
        slots={{
          toolbar: CustomToolbar,
        }}
        pagination
        paginationMode="server"
        rowCount={data?.totalCount || 0}
        paginationModel={{ page, pageSize }}
        onPaginationModelChange={handlePaginationModelChange}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        filterMode="server"
        onFilterModelChange={handleFilterChange}
        filterModel={filterModel}
      />
    </>
  );
}

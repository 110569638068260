import React, { useContext, useState } from "react";
import { Box, InputAdornment, Tab, Tabs, TextField } from "@mui/material";
import "../Purchase/Index.css";
import BudgetList from "./BudgetList";
import { BudgetContext } from "./contexBudget/BudgetContext";
import KanbanBoard from "../KanbanBoard/KanbanBoard";
import BudgetModal from "./BudgetModal";
import SearchIcon from "@mui/icons-material/Search";
import BudgetColumn from "../KanbanBoard/BudgetKanban/BudgetColumn";
import { WorkshopBudgetState } from "../../../entities/workshop-budget-state";
import { DropResult } from "@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration";
import { Budget } from "../../../entities/budget.entity";
import { PageResponse } from "../../../types/PagedData";
import { queryClient } from "../../../main";
import { InfiniteData } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { BudgetService } from "../../../services/BudgetService";

const budgetService = new BudgetService();

const onBudgetDragEnd = async (
  result: DropResult,
  sourceStateId: number,
  destinationStateId: number,
  searchTerm: string
) => {
  if (!result.destination) return;

  const { source, destination } = result;
  if (source.droppableId === destination.droppableId) return; // No cambió de columna

  const sourceQueryKey = ["budgetsByState", [sourceStateId], searchTerm];
  const destQueryKey = ["budgetsByState", [destinationStateId], searchTerm];

  const sourceData =
    queryClient.getQueryData<InfiniteData<PageResponse<Budget>>>(
      sourceQueryKey
    );
  if (!sourceData) {
    console.warn("No se encontró data en la columna de origen");
    return;
  }

  const movedItemId = parseInt(result.draggableId, 10);

  const pageWithItemIndex = sourceData.pages.findIndex(
    (page: PageResponse<Budget>) =>
      page.data.some((b: Budget) => b.id === movedItemId)
  );
  if (pageWithItemIndex === -1) {
    console.warn("No se encontró el ítem en la data de origen");
    return;
  }

  const itemPage = sourceData.pages[pageWithItemIndex];
  const itemIndex = itemPage.data.findIndex(
    (b: Budget) => b.id === movedItemId
  );
  const movedBudget = itemPage.data[itemIndex];

  if (!movedBudget) return;

  const newSourcePages = sourceData.pages.map(
    (page: PageResponse<Budget>, i: number) => {
      if (i !== pageWithItemIndex) return page;
      return {
        ...page,
        data: page.data.filter((b: Budget) => b.id !== movedItemId),
      };
    }
  );

  queryClient.setQueryData(sourceQueryKey, {
    ...sourceData,
    pages: newSourcePages,
  });

  let updatedBudget: Budget;
  try {
    updatedBudget = await budgetService.update(movedBudget.id, {
      workshop_budget_state_id: destinationStateId,
    });
    enqueueSnackbar("Se actualizó el estado del presupuesto", {
      variant: "success",
    });
  } catch (error) {
    console.error("Error updating budget state:", error);
    enqueueSnackbar("Error al actualizar el estado del presupuesto", {
      variant: "error",
    });

    queryClient.setQueryData(sourceQueryKey, sourceData);
    return;
  }

  const destData =
    queryClient.getQueryData<InfiniteData<PageResponse<Budget>>>(destQueryKey);

  if (!destData) {
    queryClient.setQueryData(destQueryKey, {
      pageParams: [],
      pages: [{ data: [updatedBudget] }],
    });
  } else {
    const newDestPages = [...destData.pages];

    newDestPages[0] = {
      ...newDestPages[0],
      data: [updatedBudget, ...newDestPages[0].data],
    };

    queryClient.setQueryData(destQueryKey, {
      ...destData,
      pages: newDestPages,
    });
  }
};

export default function BudgetIndex() {
  const { loading } = useContext(BudgetContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Board" />
            <Tab label="Backlog" />
          </Tabs>
        </Box>

        {value === 0 && (
          <>
            <Box sx={{ mb: 2 }}>
              <TextField
                variant="outlined"
                placeholder="Filtrar por ID o patente"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ width: "300px" }}
              />
            </Box>
            <KanbanBoard
              ColumnComponent={BudgetColumn}
              onDragEnd={onBudgetDragEnd}
              renderModal={() => <BudgetModal />}
              loading={loading}
              columnOrder={WorkshopBudgetState.columnOrder}
              searchTerm={searchTerm}
              responsibleList={[]}
            />
          </>
        )}

        {value === 1 && <BudgetList />}
      </Box>
    </>
  );
}

import { ReactNode, useState } from "react";
import { BudgetContext, BudgetProps } from "./BudgetContext";
import { BudgetService } from "../../../../services/BudgetService";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { Budget } from "../../../../entities/budget.entity";
import { TypeKanbanBudgetGroup } from "../../KanbanBoard/BudgetKanban/TypeKanbanBudget";

interface BudgetProviderProps {
  children: ReactNode;
}

export const BudgetProvider = ({ children }: BudgetProviderProps) => {
  const queryClient = useQueryClient();
  const budgetService = new BudgetService();
  const [open, setOpen] = useState(false);
  const [selectedBudgetData, setSelectedBudgetData] =
    useState<TypeKanbanBudgetGroup>();
  const [filters, setFilters] = useState<any>({});
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: ["budgets", filters],
      queryFn: async ({ pageParam = 1 }) => {
        const response = await budgetService.find({ page: pageParam, filters });
        setTotalCount(response.totalCount);
        setPageSize(response.pageSize);
        return response;
      },
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.data.length > 0 ? nextPage : undefined;
      },
      initialPageParam: 1,
    });

  const pagedBudgets = data?.pages.flatMap((page) => page.data) || [];

  const replaceBudgetInQueryData = (
    budgetId: number,
    updatedBudget: Budget
  ) => {
    queryClient.setQueryData(["budgets", filters], (oldData: any) => {
      if (!oldData) return oldData;

      return {
        ...oldData,
        pages: oldData.pages.map((page: any) => ({
          ...page,
          data: page.data.map((budget: Budget) =>
            budget.id === budgetId ? updatedBudget : budget
          ),
        })),
      };
    });

    queryClient.setQueryData(
      ["activeBudgets"],
      (oldData: Budget[] | undefined) => {
        if (!oldData) return oldData;

        return oldData.map((budget) =>
          budget.id === budgetId ? updatedBudget : budget
        );
      }
    );
  };

  const updateSelectedBudgetData = (
    budgetId: number,
    updates: Partial<Budget>
  ) => {
    if (!selectedBudgetData) return;

    const updatedBudgets = selectedBudgetData.budgets.map((budgetItem) => {
      if (budgetItem.originalBudget.id === budgetId) {
        const updatedBudget = { ...budgetItem, ...updates };
        return updatedBudget;
      } else {
        return budgetItem;
      }
    });

    setSelectedBudgetData({
      ...selectedBudgetData,
      budgets: updatedBudgets,
    });
  };

  const updateBudget = async (
    budgetId: number,
    updatedBudget: Partial<Budget>
  ) => {
    try {
      const updated = await budgetService.update(budgetId, updatedBudget);

      replaceBudgetInQueryData(budgetId, updated);

      updateSelectedBudgetData(budgetId, updated);
    } catch (error) {
      console.error("Error updating budget:", error);
    }
  };

  const updateMechanics = async (budgetId: number, userIds: number[]) => {
    try {
      const updatedBudget = await budgetService.updateMechanics(
        budgetId,
        userIds
      );

      replaceBudgetInQueryData(budgetId, updatedBudget);

      updateSelectedBudgetData(budgetId, updatedBudget);
    } catch (error) {
      console.error("Error updating mechanics:", error);
    }
  };

  const budgetContextValue: BudgetProps = {
    open,
    setOpen,
    pagedBudgets,
    selectedBudgetData,
    setSelectedBudgetData,
    loading: isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    updateBudget,
    updateMechanics,
    setFilters,
    totalCount,
    pageSize,
  };
  return (
    <BudgetContext.Provider value={budgetContextValue}>
      {children}
    </BudgetContext.Provider>
  );
};

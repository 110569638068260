import ApiService from "./ApiService";
import { BaseApplicationService } from "../interfaces/base-applicaton-service.interface";
import { Workshop } from "../entities/workshop.entity";
import { User } from "../entities/user.entity";

type Params = {};

export class WorkshopService
  implements BaseApplicationService<Workshop, Params>
{
  findOneById(_id: number): Promise<Workshop | undefined> {
    throw new Error("Method not implemented.");
  }
  findOne(_params: Params): Promise<Workshop> {
    throw new Error("Method not implemented.");
  }
  find(_params?: Params | undefined): Promise<Workshop[]> {
    throw new Error("Method not implemented.");
  }
  async users(id: number) {
    const response = await ApiService.get(`/api_v2/workshop/users?id=${id}`);

    if (response.data.success == false) {
      return { errors: response.data.data };
    }

    return response.data.data.map((u: any) => new User(u)) as User[];
  }
}

import { ReactNode, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import {
  DragDropContext,
  DropResult,
} from "@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration";
import { User } from "../../../entities/user.entity";
import useDebounce from "../../../hooks/useDebounce";

interface KanbanProps {
  onDragEnd: (
    result: DropResult,
    sourceStateId: number,
    destinationStateId: number,
    searchTerm: string
  ) => Promise<void>;
  renderModal: () => ReactNode;
  loading: boolean;
  columnOrder: number[];
  searchTerm: string;
  responsibleList: User[];
  ColumnComponent: React.ComponentType<{
    stateId: number;
    responsibleList: User[];
    searchTerm: string;
  }>;
}

export default function Kanban({
  onDragEnd,
  renderModal,
  loading,
  columnOrder,
  searchTerm,
  responsibleList,
  ColumnComponent,
}: KanbanProps) {
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useDebounce(() => {
    setDebouncedSearchTerm(searchTerm);
  }, 500);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            minWidth: "100%",
            maxHeight: "calc(100vh - 220px)",
            overflowX: "auto",
          }}
        >
          <DragDropContext
            onDragEnd={(result: DropResult) => {
              if (!result.destination) return;
              const sourceStateId = parseInt(result.source.droppableId, 10);
              const destinationStateId = parseInt(
                result.destination.droppableId,
                10
              );
              void onDragEnd(
                result,
                sourceStateId,
                destinationStateId,
                debouncedSearchTerm
              );
            }}
          >
            {renderModal()}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
                //minHeight: "75vh",
                height: "100%",
              }}
            >
              {columnOrder.map((stateId) => (
                <ColumnComponent
                  key={stateId}
                  stateId={stateId}
                  responsibleList={responsibleList}
                  searchTerm={debouncedSearchTerm}
                />
              ))}
            </Box>
          </DragDropContext>
        </Box>
      )}
    </>
  );
}

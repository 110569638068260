import {
  Draggable,
  DraggableProvided,
} from "@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration";
import { Box } from "@mui/material";
import { ReactNode } from "react";

interface ItemProps<T> {
  task: T;
  index: number;
  handleOpen: (item: T) => void;
  renderItem: (task: T) => ReactNode;
}

function Item<T extends { id: number | string }>({
  task,
  index,
  handleOpen,
  renderItem,
}: ItemProps<T>) {
  return (
    <Draggable
      key={task.id.toString()}
      draggableId={task.id.toString()}
      index={index}
    >
      {(draggableProvided: DraggableProvided) => (
        <Box
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
          onClick={() => handleOpen(task)}
          sx={{ width: "100%" }}
        >
          {renderItem(task)}
        </Box>
      )}
    </Draggable>
  );
}

export default Item;

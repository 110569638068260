import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Backlog from "./Backlog";
import KanbanBoard from "../KanbanBoard/KanbanBoard";
import { PurchaseContext } from "./context/PurchaseContext";
import { InputAdornment, Modal, TextField } from "@mui/material";
import ModalDetail from "../../Purchase/ModalDetail";
import { Car } from "../../../entities/car.entity";
import { WorkshopPurchase } from "../../../entities/workshop-purchase.entity";
import { useContext, useEffect, useState } from "react";
import { WorkshopService } from "../../../services/WorkshopService";
import { User } from "../../../entities/user.entity";
import SearchIcon from "@mui/icons-material/Search";
import { WorkshopPurchaseState } from "../../../entities/workshop-purchase-state.entity";
import PurchaseColumn from "../KanbanBoard/PurchaseKanban/PurchaseColumn";

import { enqueueSnackbar } from "notistack";
import { WorkshopPurchaseService } from "../../../services/WorkshopPurchaseService";
import { queryClient } from "../../../main";
import { DropResult } from "@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration";
import { WorkshopPurchaseItem } from "../../../entities/workshop-purchase-item.entity";

const purchaseService = new WorkshopPurchaseService();

interface PagedData {
  pages: { data: WorkshopPurchase[] }[];
}

const onPurchaseDragEnd = async (
  result: DropResult,
  sourceStateId: number,
  destinationStateId: number,
  searchTerm: string
) => {
  const { source, destination } = result;
  if (!destination || source.droppableId === destination.droppableId) {
    return;
  }

  const movedPurchaseId = parseInt(result.draggableId);
  const sourceQueryKey = ["purchasesByState", sourceStateId, searchTerm];
  const destQueryKey = ["purchasesByState", destinationStateId, searchTerm];

  const sourceData = queryClient.getQueryData<PagedData>(sourceQueryKey);
  if (!sourceData) {
    console.warn("No se encontró data en la columna de origen");
    return;
  }

  const pageIndex = sourceData.pages.findIndex(
    (page: { data: WorkshopPurchase[] }) =>
      page.data.some((p: WorkshopPurchase) => p.id === movedPurchaseId)
  );

  if (pageIndex === -1) {
    console.warn("No se encontró el ítem en la data de origen");
    return;
  }

  const page = sourceData.pages[pageIndex];
  const itemIndex = page.data.findIndex(
    (p: WorkshopPurchase) => p.id === movedPurchaseId
  );
  const movedPurchase = page.data[itemIndex];

  if (
    destinationStateId === WorkshopPurchaseState.QUOTED_ID &&
    movedPurchase &&
    !movedPurchase.workshopPurchaseItems.every(
      (item: WorkshopPurchaseItem) => item.price !== null
    )
  ) {
    const errorMessage =
      "No se puede mover al estado 'Cotizado' sin definir todos los precios";
    console.warn(errorMessage);
    enqueueSnackbar(errorMessage, { variant: "error" });
    return;
  }

  const newSourcePages = sourceData.pages.map(
    (page: { data: WorkshopPurchase[] }, i: number) => {
      if (i !== pageIndex) return page;
      return {
        ...page,
        data: page.data.filter(
          (p: WorkshopPurchase) => p.id !== movedPurchaseId
        ),
      };
    }
  );

  queryClient.setQueryData(sourceQueryKey, {
    ...sourceData,
    pages: newSourcePages,
  });

  let updatedPurchase: WorkshopPurchase;
  try {
    updatedPurchase = await purchaseService.update(movedPurchaseId, {
      workshop_purchase_state_id: destinationStateId,
    });
    enqueueSnackbar("Se actualizó el estado de la compra", {
      variant: "success",
    });
  } catch (error) {
    enqueueSnackbar("Error al actualizar el estado de la compra", {
      variant: "error",
    });
    queryClient.setQueryData(sourceQueryKey, sourceData);
    return;
  }

  const destData = queryClient.getQueryData<PagedData>(destQueryKey);

  if (!destData) {
    queryClient.setQueryData(destQueryKey, {
      pageParams: [],
      pages: [{ data: [updatedPurchase] }],
    });
  } else {
    const newDestPages = [...destData.pages];
    newDestPages[0] = {
      ...newDestPages[0],
      data: [updatedPurchase, ...newDestPages[0].data],
    };

    queryClient.setQueryData(destQueryKey, {
      ...destData,
      pages: newDestPages,
    });
  }
};

export default function PurchaseIndex() {
  const {
    selectedPurchase,
    setSelectedPurchase,
    open,
    setOpen,
    loading,
    updatePurchase,
    purchases,
  } = useContext(PurchaseContext);

  const [value, setValue] = useState(0);
  const [responsibleList, setResponsibleList] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const workshopService = new WorkshopService();
    const fetchUsers = async () => {
      if (purchases.length > 0) {
        const users = await workshopService.users(purchases[0]?.workshop.id);
        if (Array.isArray(users)) {
          setResponsibleList(users);
        }
      }
    };
    if (purchases.length > 0) {
      void fetchUsers();
    }
  }, [purchases]);

  const renderPurchaseModal = () => {
    return (
      selectedPurchase && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            <ModalDetail
              carSummary={Car.getSummary(selectedPurchase.budget.car)}
              purchase={selectedPurchase}
              handleClose={() => setOpen(false)}
              onPurchaseChange={async (updatedPurchase: WorkshopPurchase) => {
                await updatePurchase(updatedPurchase.id, updatedPurchase);
                setSelectedPurchase(updatedPurchase);
              }}
            />
          </Box>
        </Modal>
      )
    );
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Board" />
          <Tab label="Backlog" />
        </Tabs>
      </Box>

      {value === 0 && (
        <>
          <Box sx={{ mb: 2 }}>
            <TextField
              variant="outlined"
              placeholder="Filtrar por ID o patente"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: "300px" }}
            />
          </Box>
          <KanbanBoard
            ColumnComponent={PurchaseColumn}
            onDragEnd={onPurchaseDragEnd}
            renderModal={renderPurchaseModal}
            loading={loading}
            columnOrder={WorkshopPurchaseState.columnOrder}
            searchTerm={searchTerm}
            responsibleList={responsibleList}
          />
        </>
      )}

      {value === 1 && <Backlog />}
    </Box>
  );
}

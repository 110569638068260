import { useRef, useEffect, ReactNode } from "react";
import { User } from "../../../entities/user.entity";
import { InfiniteQueryOptions } from "../../../types/InfiniteQueryOptions";
import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";
import { PageResponse } from "../../../types/PagedData";
import BasicColumn from "./BasicColumn";

interface InfiniteColumnProps<T, Item> {
  stateId: number;
  responsibleList?: User[];
  getColumnBackgroundColor: (columnId: number) => string;
  handleOpen: (item: T) => void;
  renderItem: (task: T) => ReactNode;
  searchTerm: string;
  useInfiniteDataQuery: (
    options: InfiniteQueryOptions
  ) => UseInfiniteQueryResult<InfiniteData<PageResponse<Item>, unknown>, Error>;
  generateColumnItems: (
    data: Item[],
    responsibleList: User[],
    stateId: number
  ) => T[];
  stateLabels: Record<number, string>;
  stateFilterFn: (stateId: number) => (item: Item) => boolean;
}

function InfiniteColumn<T extends { id: number | string }, Item>({
  stateId,
  responsibleList,
  getColumnBackgroundColor,
  handleOpen,
  renderItem,
  searchTerm,
  useInfiniteDataQuery,
  generateColumnItems,
  stateLabels,
  stateFilterFn,
}: InfiniteColumnProps<T, Item>) {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteDataQuery({
      stateId,
      searchTerm,
    });

  const allItems = data?.pages.flatMap((page) => page.data) || [];
  const totalCount = data?.pages[0] ? data?.pages[0].totalCount : 0;
  const stateFilter = stateFilterFn(stateId);
  const filteredByState = allItems.filter(stateFilter);
  const items = generateColumnItems(
    filteredByState,
    responsibleList || [],
    stateId
  );

  const loadMoreRef = useRef(null);

  useEffect(() => {
    if (!loadMoreRef.current) return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
        void fetchNextPage();
      }
    });

    observer.observe(loadMoreRef.current);

    return () => {
      observer.disconnect();
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  const columnName = stateLabels[stateId] || "Desconocido";
  const bgColor = getColumnBackgroundColor(stateId);

  return (
    <BasicColumn
      stateId={stateId}
      items={items}
      columnName={columnName}
      bgColor={bgColor}
      handleOpen={handleOpen}
      renderItem={renderItem}
      totalCount={totalCount}
      isLoading={isLoading || isFetchingNextPage}
    >
      <div ref={loadMoreRef} style={{ height: 1 }} />
    </BasicColumn>
  );
}

export default InfiniteColumn;

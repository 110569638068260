import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  InputAdornment,
  IconButton,
  FormHelperText,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Budget } from "../../entities/budget.entity";
import { Car } from "../../entities/car.entity";
import { useEffect, useState } from "react";
import { BudgetService } from "../../services/BudgetService";
import { WorkshopPurchase } from "../../entities/workshop-purchase.entity";
import { FieldError } from "../../types/FieldError";
import { User } from "../../entities/user.entity";
import { WorkshopService } from "../../services/WorkshopService";
import { WorkshopPurchaseService } from "../../services/WorkshopPurchaseService";
import { WorkshopPurchaseState } from "../../entities/workshop-purchase-state.entity";
import { WorkshopPurchaseStateService } from "../../services/WorkshopPurchaseStateService";
import { useSnackbar } from "notistack";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useDarkMode } from "../ThemeContext";
import PurchasePDF from "./PDF/PurchasePDF";
import useWorkshopUsers from "../../hooks/useWorkshopUsers";

interface ComponentProps {
  carSummary: string;
  car: Car;
  budget: Budget;
  purchase: WorkshopPurchase;
  onBudgetChange: Function;
  onPurchaseChange: Function;
  isEditionDisabled?: boolean;
}

const styles = {
  select: {
    backgroundColor: "#2e7d32",
    color: "white",
    textAlign: "center" as const,
  },
  rowCart: {
    width: "100%",
    padding: "6px",
    whiteSpace: "nowrap",
  },
};

export default function TopPanel({
  carSummary,
  car,
  budget,
  purchase,
  onBudgetChange,
  onPurchaseChange,
  isEditionDisabled = false,
}: ComponentProps) {
  const [isEditingExpirationDays, setIsEditingExpirationDays] = useState(false);
  const [newExpirationDays, setNewExpirationDays] = useState(
    budget.expiration_days || Budget.DEFAULT_EXPIRATION_DAYS
  );
  const [isEditingNewResponsable, setIsEditingNewResponsable] = useState(false);
  const [responsibleList, setResponsibleList] = useState<User[]>([]);
  const [errors, setErrors] = useState<FieldError[] | undefined>();
  const [purchaseStates, setPurchaseStates] = useState<WorkshopPurchaseState[]>(
    []
  );

  const stateService = new WorkshopPurchaseStateService();
  const { enqueueSnackbar } = useSnackbar();
  const budgetService = new BudgetService();
  const purchaseService = new WorkshopPurchaseService();
  const workshopService = new WorkshopService();
  const { isDarkMode } = useDarkMode();
  const { data: users } = useWorkshopUsers(purchase.workshop.id);

  const onSaveNewExpirationDays = () => {
    setErrors([]);

    budgetService
      .update(budget.id, { expiration_days: newExpirationDays })
      .then((budget) => {
        if ("errors" in budget) {
          setErrors(budget.errors as FieldError[]);
          return;
        }
        onBudgetChange(budget);
        setIsEditingExpirationDays(false);
      })
      .catch((error) => {
        setErrors([
          {
            field: "expiration_days",
            message:
              "Ha ocurrido un error al actualizar los días de expiración. Por favor intente nuevamente.",
          },
        ]);
        console.error("Error al actualizar días de expiración:", error);
      });
  };

  const changeResponsable = (event: SelectChangeEvent) => {
    purchaseService
      .update(purchase.id, { responsible_id: Number(event.target.value) })
      .then((purchase) => {
        if ("errors" in purchase) {
          enqueueSnackbar("Error al actualizar el responsable", {
            variant: "error",
          });
          return;
        }
        onPurchaseChange(purchase);
      })
      .finally(() => setIsEditingNewResponsable(false));
  };

  useEffect(() => {
    workshopService.users(purchase.workshop.id).then((u) => {
      if (!("errors" in u)) {
        setResponsibleList(u as User[]);
      }
    });
  }, []);

  useEffect(() => {
    stateService.find({}).then((states) => {
      setPurchaseStates(states);
    });
  }, [purchase]);

  useEffect(() => {
    if (!purchase?.workshopPurchaseItems) return;

    const newPurchaseStates = purchase.workshopPurchaseItems.map(
      (item) => item.workshopPurchaseItemState
    );

    setPurchaseStates(newPurchaseStates);
  }, [purchase]);

  const handleUpdatePurchaseState = (event: SelectChangeEvent) => {
    setErrors(undefined);
    void purchaseService
      .update(purchase.id, {
        workshop_purchase_state_id: Number(event.target.value),
      })
      .then((updatedPurchase) => {
        if ("errors" in updatedPurchase) {
          enqueueSnackbar("Error al actualizar el estado de la compra", {
            variant: "error",
          });
          return;
        }
        const oldItemStates = purchase.workshopPurchaseItems.map(
          (i) => i.workshopPurchaseItemState.id
        );
        const newItemStates = updatedPurchase.workshopPurchaseItems.map(
          (i) => i.workshopPurchaseItemState.id
        );

        if (JSON.stringify(oldItemStates) !== JSON.stringify(newItemStates)) {
          enqueueSnackbar("Se actualizó el estado de los repuestos");
        }

        onPurchaseChange(updatedPurchase);
      });
  };

  const prices = purchase.workshopPurchaseItems.map((item) => item.price);

  const allPricesSet = prices.every((price) => price !== null);

  const selectedResponsible = responsibleList.find(
    (r) => r.id === purchase.responsible_id
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <h2 style={{ margin: "0px" }}>
            {budget.previous_budget_id
              ? `${carSummary} (Recotización)`
              : carSummary}
          </h2>
        </Box>
        <Box
          sx={{
            display: "flex",

            alignItems: "center",
          }}
        >
          <Box sx={{ margin: "0px 20px 0px 20px" }}>
            <PDFDownloadLink
              document={<PurchasePDF purchase={purchase} users={users} />}
              fileName={`SC-${purchase.id}.pdf`}
            >
              <IconButton
                sx={{
                  "&:hover, &.Mui-focusVisible": {
                    backgroundColor: "rgba(214, 20, 50, 0.08)",
                  },
                }}
              >
                <PictureAsPdfIcon
                  sx={{
                    color: isDarkMode ? "#407AD6" : "#b6112b",
                  }}
                />
              </IconButton>
            </PDFDownloadLink>
          </Box>
          <FormControl sx={{ minWidth: "250px" }} fullWidth>
            <Select
              disabled={!allPricesSet || isEditionDisabled}
              inputProps={{ "aria-label": "Without label" }}
              color="success"
              style={styles.select}
              size="small"
              onChange={handleUpdatePurchaseState}
              value={purchase.workshopPurchaseState.id.toString()}
              error={Boolean(
                errors &&
                  errors.find(
                    (e: any) => e.field === "workshop_purchase_state_id"
                  )
              )}
            >
              {purchaseStates.map((state) => (
                <MenuItem key={state.id} value={state.id}>
                  {state.name}
                </MenuItem>
              ))}
            </Select>
            {errors &&
              errors.find(
                (e: any) => e.field === "workshop_purchase_state_id"
              ) && (
                <FormHelperText error={true}>
                  {
                    errors.find(
                      (e: any) => e.field === "workshop_purchase_state_id"
                    )?.message
                  }
                </FormHelperText>
              )}
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Paper sx={{ width: "100%" }}>
          <Table
            size="small"
            sx={{
              display: "flex",
              width: "100%",
              borderRadius: "4px",
              overflow: "hidden",
            }}
          >
            <TableBody sx={{ width: "100%" }}>
              <TableRow>
                <TableCell sx={{ padding: "6px 6px 6px 16px" }} scope="row">
                  <strong>Motor:</strong>
                </TableCell>
                <TableCell sx={styles.rowCart} scope="row">
                  {car.engine_number}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: "6px 6px 6px 16px" }} scope="row">
                  <strong>Chasis:</strong>
                </TableCell>
                <TableCell sx={styles.rowCart} scope="row">
                  {car.chassis_number}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: "6px 6px 6px 16px" }} scope="row">
                  <strong>Color:</strong>
                </TableCell>
                <TableCell sx={styles.rowCart} scope="row">
                  {car.color}
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody sx={{ width: "100%" }}>
              <TableRow>
                <TableCell
                  sx={{ whiteSpace: "nowrap", padding: "6px" }}
                  scope="row"
                >
                  <strong>Cliente:</strong>
                </TableCell>
                <TableCell sx={styles.rowCart} scope="row">
                  {budget.company.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ whiteSpace: "nowrap", padding: "6px" }}
                  scope="row"
                >
                  <strong>Tipo de Servicio:</strong>
                </TableCell>
                <TableCell sx={styles.rowCart} scope="row">
                  {purchase.budget.request_repair_type_name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ whiteSpace: "nowrap", padding: "6px" }}
                  scope="row"
                >
                  <strong>Km al solicitar:</strong>
                </TableCell>
                <TableCell sx={styles.rowCart} scope="row">
                  {budget.usage}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Paper
          sx={{
            minWidth: "250px",
            marginLeft: "30px",
          }}
        >
          <Box sx={{ padding: "16px", height: "100%" }}>
            {isEditingNewResponsable ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    justifyItems: "center",
                  }}
                >
                  <InputLabel id="responsible-select-label">
                    Responsable
                  </InputLabel>
                  <Select
                    labelId="responsible-select-label"
                    id="responsible-select"
                    value={purchase.responsible_id?.toString()}
                    label="Responsable"
                    onChange={changeResponsable}
                  >
                    {responsibleList.map((r) => (
                      <MenuItem value={r.id}>
                        {r.name} {r.lastname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <IconButton
                  color="error"
                  onClick={() => setIsEditingNewResponsable(false)}
                  sx={{ height: "20px" }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  cursor: "pointer",
                  width: "100%",
                  justifyContent: "space-between",
                }}
                onClick={() => setIsEditingNewResponsable(true)}
              >
                <strong>Responsable: </strong>

                <Box
                  className="hoverable"
                  sx={{
                    whiteSpace: "nowrap",
                    width: "100%",
                    marginLeft: "12px",
                  }}
                >
                  {selectedResponsible ? (
                    selectedResponsible.fullname
                  ) : (
                    <Typography>No definido</Typography>
                  )}
                </Box>
              </Box>
            )}

            <Box
              sx={{
                textAlign: "center",
                width: "100%",
              }}
            >
              {isEditingExpirationDays ? (
                <TextField
                  type="number"
                  onChange={(event) =>
                    setNewExpirationDays(Number(event.target.value))
                  }
                  error={Boolean(
                    errors?.find((e) => e.field == "expiration_days")
                  )}
                  helperText={
                    errors?.find((e) => e.field == "expiration_days")?.message
                  }
                  value={newExpirationDays}
                  size="small"
                  sx={{ marginTop: "10px" }}
                  label="Días de Validez"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={onSaveNewExpirationDays}
                          edge="end"
                          color="info"
                        >
                          <CheckIcon />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => setIsEditingExpirationDays(false)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              ) : (
                <>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      <strong>Días de Validez: </strong>
                    </Box>

                    <Box
                      className="hoverable"
                      sx={{
                        cursor: "pointer",
                        textAlign: "center",
                        marginLeft: "12px",
                      }}
                      onClick={() => setIsEditingExpirationDays(true)}
                    >
                      {budget.expiration_days || Budget.DEFAULT_EXPIRATION_DAYS}
                    </Box>
                  </Box>
                </>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ whiteSpace: "nowrap" }}>
                <strong>En taller:</strong>
              </Box>
              <Box sx={{ width: "100%", marginLeft: "12px" }}>
                {car.in_workshop ? "Si" : "No"}
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

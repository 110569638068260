import { useInfiniteQuery } from "@tanstack/react-query";
import { PageResponse } from "../../../types/PagedData";

export function useInfiniteKanbanQuery<T>(
  fetchFn: ({
    pageParam,
    queryKey,
  }: {
    pageParam: number | undefined;
    queryKey: [string, number, string];
    }) => Promise<PageResponse<T>>,
  queryKey: [string, number, string]
) {
  return useInfiniteQuery<PageResponse<T>>({
    queryKey: queryKey,
    queryFn: (context) =>
      fetchFn({
        queryKey: context.queryKey as [string, number, string],
        pageParam: context.pageParam as number | undefined,
      }),

    getNextPageParam: (lastPage) => {
      const { totalCount, pageSize, currentPage } = lastPage;
      const totalPages = Math.ceil(totalCount / pageSize);
      return currentPage && currentPage < totalPages ? currentPage + 1 : undefined;
    },
    initialPageParam: 1,
    staleTime: 1000 * 60 * 5,
  });
}

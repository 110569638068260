import React, { createContext } from "react";
import { WorkshopPurchase } from "../../../../entities/workshop-purchase.entity";

export type PurchaseProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPurchase: WorkshopPurchase | undefined;
  setSelectedPurchase: React.Dispatch<
    React.SetStateAction<WorkshopPurchase | undefined>
  >;
  purchases: WorkshopPurchase[];
  setPurchases: React.Dispatch<React.SetStateAction<WorkshopPurchase[]>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  totalCount: number;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  updatePurchase: (
    purchaseId: number,
    updatedPurchase: Partial<WorkshopPurchase>
  ) => Promise<void>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  isPlaceholderData: boolean;
  filters: object;
};

export const PurchaseContext = createContext<PurchaseProps>({
  open: false,
  setOpen: () => {},
  selectedPurchase: undefined,
  setSelectedPurchase: () => {},
  purchases: [],
  setPurchases: () => {},
  loading: true,
  setLoading: () => {},
  totalCount: 0,
  setFilters: () => {},
  updatePurchase: async () => {},
  page: 1,
  setPage: () => {},
  pageSize: 20,
  setPageSize: () => {},
  isPlaceholderData: false,
  filters: {},
});

import { Budget } from "../../../../entities/budget.entity";
import InfiniteColumn from "../InfiniteColumn";
import { TypeKanbanBudget, TypeKanbanBudgetGroup } from "./TypeKanbanBudget";
import { useInfiniteBudgetsByState } from "./useInfiniteBudgetByState";
import { User } from "../../../../entities/user.entity";
import { useContext } from "react";
import { BudgetContext } from "../../Budget/contexBudget/BudgetContext";
import BudgetKanbanItem from "./BudgetKanbanItem";
import { getTimeDifferenceInfo } from "../../../../utils/dateUtils";
import { WorkshopBudgetState } from "../../../../entities/workshop-budget-state";
import { WorkshopPurchaseState } from "../../../../entities/workshop-purchase-state.entity";

const generateBudgetItems = (budgets: Budget[]): TypeKanbanBudgetGroup[] => {
  const groups = new Map<string, TypeKanbanBudget[]>();

  budgets.forEach((budget) => {
    const lastModificationDate = budget.last_state_change_date;
    const { formattedHoverDate, formattedDate, background, color } =
      getTimeDifferenceInfo(lastModificationDate);

    const purchase_state_name = budget.workshop_purchase_state_id
      ? WorkshopPurchaseState.labels[budget.workshop_purchase_state_id]
      : "";

    const groupKey = `${budget.car.identification}-${budget.appointment_date}-${budget.workshop_budget_state_id}`;
    const singleItem: TypeKanbanBudget = {
      originalBudget: budget,
      tags: [
        {
          budgetId: budget.id,
          title: `PR ${budget.id.toString()} - ${
            budget.budgetState.client_facing_name
          }`,
          bg: "#cffafe",
          text: "#0891b2",
          PurchaseState: `SC ${purchase_state_name}`,
        },
      ],
      lastModificationHoverDate: formattedHoverDate,
      lastModificationFormattedDate: formattedDate,
      lastModificationBg: background,
      lastModificationColor: color,
    };

    if (!groups.has(groupKey)) {
      groups.set(groupKey, []);
    }
    groups.get(groupKey)!.push(singleItem);
  });

  const result: TypeKanbanBudgetGroup[] = [];

  groups.forEach((groupItems, groupKey) => {
    const groupCard: TypeKanbanBudgetGroup = {
      id: groupKey,
      budgets: groupItems,
    };

    result.push(groupCard);
  });

  return result;
};

export default function BudgetColumn({
  stateId,
  responsibleList,
  searchTerm,
}: {
  stateId: number;
  responsibleList: User[];
  searchTerm: string;
}) {
  const { setOpen, setSelectedBudgetData } = useContext(BudgetContext);

  const renderItem = (task: TypeKanbanBudgetGroup) => {
    return <BudgetKanbanItem task={task} />;
  };

  return (
    <InfiniteColumn<TypeKanbanBudgetGroup, Budget>
      key={stateId}
      stateId={stateId}
      responsibleList={responsibleList}
      getColumnBackgroundColor={WorkshopBudgetState.getColumnBackgroundColor}
      searchTerm={searchTerm}
      handleOpen={(item: TypeKanbanBudgetGroup) => {
        setSelectedBudgetData(item);
        setOpen(true);
      }}
      renderItem={renderItem}
      useInfiniteDataQuery={useInfiniteBudgetsByState}
      generateColumnItems={generateBudgetItems}
      stateLabels={WorkshopBudgetState.labels}
      stateFilterFn={(stateId) => (item: Budget) =>
        item.workshop_budget_state_id === stateId}
    />
  );
}

import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { GridFilterInputValueProps, GridFilterItem } from "@mui/x-data-grid";
import { MutableRefObject, useState } from "react";
import AutocompleteFilter from "./AutocompleteFilter";
import { GridApiCommunity } from "@mui/x-data-grid/internals";

export interface ExtendedGridFilterItem extends GridFilterItem {
  filterType?: string;
}

interface VehicleFilterInputProps extends GridFilterInputValueProps {
  item: ExtendedGridFilterItem;
}

export const VehicleFilterInput = ({
  item,
  applyValue,
  brands,
  carModels,
  carVersions,
  loading,
  apiRef,
}: VehicleFilterInputProps & {
  brands: string[];
  carModels: string[];
  carVersions: string[];
  loading: boolean;
  apiRef: MutableRefObject<GridApiCommunity>;
}) => {
  const [filterType, setFilterType] = useState(item.filterType || "");

  const [inputValue, setInputValue] = useState<string>(item.value || "");

  const filterOptions = [
    { value: "identification", label: "Patente" },
    { value: "brand_id", label: "Marca" },
    { value: "car_model_id", label: "Modelo" },
    { value: "car_version_id", label: "Versión" },
  ];

  const handleFilterTypeChange = (e: any) => {
    const newType = e.target.value;
    setFilterType(newType);
    setInputValue("");
    applyValue({
      ...item,
      value: "",
      filterType: newType,
    } as ExtendedGridFilterItem);
  };

  const getFilterData = () => {
    switch (filterType) {
      case "brand_id":
        return { options: brands, loading };
      case "car_model_id":
        return { options: carModels, loading };
      case "car_version_id":
        return { options: carVersions, loading };
      default:
        return { options: [], loading: false };
    }
  };

  const { options, loading: isLoading } = getFilterData();

  return (
    <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
      <InputLabel>Filtrar</InputLabel>
      <Select
        label="Filtrar por"
        size="small"
        value={filterType}
        onChange={handleFilterTypeChange}
        fullWidth
        sx={{ minWidth: "100px" }}
      >
        {filterOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>

      {(() => {
        switch (filterType) {
          case "identification":
            return (
              <Tooltip title="Presionar Enter para aplicar">
                <TextField
                  fullWidth
                  label="Buscar por patente"
                  sx={{ minWidth: "150px", height: "100%" }}
                  variant="standard"
                  value={inputValue}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      applyValue({ ...item, value: inputValue });
                    }
                  }}
                  onBlur={() => {
                    applyValue({ ...item, value: inputValue });
                  }}
                />
              </Tooltip>
            );
          case "brand_id":
          case "car_model_id":
          case "car_version_id":
            return (
              <AutocompleteFilter
                item={item}
                applyValue={applyValue}
                label={`Seleccionar ${filterOptions
                  .find((opt) => opt.value === filterType)
                  ?.label.toLowerCase()}`}
                loading={isLoading}
                options={options}
                getOptionLabel={(option) => option.name || ""}
                getOptionValue={(option) => option.id || ""}
                apiRef={apiRef}
              />
            );
          default:
            return null;
        }
      })()}
    </Box>
  );
};
